import { navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { logout } from '../utils/Auth';
import { LocaleContext } from './ContextProvider';
import { Layout } from './Layout';
import LoadingOverlay from './LoadingOverlay';
import Section from './Section';

export const LogoutPage: React.FC = () => {
  const { rootPath } = useContext(LocaleContext);

  useEffect(() => {
    let isCancelled = false;
    const Logout = async () => {
      await logout();
      if (!isCancelled) {
        navigate(rootPath, { replace: true });
      }
    };

    Logout();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Layout
      colorTheme="light"
      title={`Logout - CloudFit`}
      description="Logout Page"
      hideHeader>
      <Section.Container backgroundColor="light" id="login">
        <LoadingOverlay loadingText="LOGGING OUT" visible={true} size={80} />
      </Section.Container>
    </Layout>
  );
};

export default LogoutPage;
