import React from 'react';
import ContextProvider from '../components/ContextProvider';
import Logout from '../components/Logout';

const LogoutPage = ({ localeCode = 'en-US' }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <Logout />
  </ContextProvider>
);

export default LogoutPage;
