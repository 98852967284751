import React from 'react';
import { Flex, Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import TextLibrary from './TextLibrary';

export interface LoadingOverlayInterface {
  visible?: boolean;
  position?: string;
  backgroundColor?: string;
  textColor?: string;
  highlightColor?: string;
  loadingText?: string;
  size?: number;
}

// Loading Screen
// Use a React Hook for the visible state on the page you want to use this loading screen
// Set state to visible to make loading display
export default function LoadingOverlay({
  visible = false,
  position = 'fixed',
  backgroundColor = 'rgba(34, 34, 34, 0.85)',
  textColor = 'light',
  highlightColor = 'cloudFitBlue',
  loadingText = 'LOADING',
  size = 120,
}: LoadingOverlayInterface) {
  return (
    <Box
      sx={{
        position,
        display: visible ? 'block' : 'none',
        width: '100%',
        height: '100%',
        backgroundColor,
        zIndex: 999999,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Flex
        flexWrap="nowrap"
        flexDirection="column"
        width="100%"
        height="100%"
        color={textColor}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            borderRadius: '50%',
            borderWidth: size / 8,
            borderStyle: 'solid',
            borderColor: textColor,
            borderTopColor: highlightColor,
            width: size,
            height: size,
            '-webkit-animation': 'spin 2s linear infinite',
            animation: 'spin 2s linear infinite',

            '@-webkit-keyframes spin': {
              '0%': {
                '-webkit-transform': 'rotate(0deg)',
              },
              '100%': {
                '-webkit-transform': 'rotate(360deg)',
              },
            },

            '@keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
          }}
        />
        <TextLibrary.H3 as="p" textAlign={['center']} mt={[2]}>
          {loadingText}
        </TextLibrary.H3>
      </Flex>
    </Box>
  );
}

LoadingOverlay.propTypes = {
  visible: PropTypes.bool,
  position: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  highlightColor: PropTypes.string,
  loadingText: PropTypes.string,
  size: PropTypes.number,
};
